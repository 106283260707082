var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"publish-settings"},[_c('page-title',[_c('save-channel-config',{attrs:{"slot":"custom-button"},slot:"custom-button"})],1),_c('slots'),_c('a-card',{staticClass:"mt-5"},[_c('a-alert',{attrs:{"show-icon":""}},[_c('div',{attrs:{"slot":"message"},domProps:{"innerHTML":_vm._s(_vm.$t('post_style_mini_help_message'))},slot:"message"})]),_c('create-if-empty',{attrs:{"options":_vm.$store.getters.styleOptionsNames,"empty-message":_vm.$t('styles_create_empty_message')},on:{"onCreateClick":_vm.gotoStyleCreate}},[_c('select-input',{attrs:{"setup":{
          'func': _vm.channelConfigSetup,
          'args': {
            'key': 'text_post_style',
            'options': _vm.$store.getters.styleOptionsNames,
            'multiple': false,
            'clearable': false,
            'hasAnchor': true,
          },
        }}}),_c('select-input',{attrs:{"setup":{
          'func': _vm.channelConfigSetup,
          'args': {
            'key': 'media_post_style',
            'options': _vm.$store.getters.styleOptionsNames,
            'multiple': false,
            'clearable': false,
            'hasAnchor': true,
          },
        }}}),_c('select-input',{attrs:{"setup":{
          'func': _vm.channelConfigSetup,
          'args': {
            'key': 'album_post_style',
            'options': _vm.$store.getters.styleOptionsNames,
            'multiple': false,
            'clearable': false,
            'hasAnchor': true,
          },
        }}})],1),_c('black-white-list-input',{staticClass:"mb-0",attrs:{"setup":{
        'func': _vm.channelConfigSetup,
        'args': {
          'key': 'replace_tags',
          'hasAnchor': true,
        }
      }}})],1),_c('a-card',{staticClass:"mt-5"},[_c('switch-input',{staticClass:"mt-0",attrs:{"setup":{
        'func': _vm.channelConfigSetup,
        'args': {
          'key': 'replace_user_forwards',
          'hasAnchor': true,
        },
      }}}),_c('switch-input',{attrs:{"setup":{
        'func': _vm.channelConfigSetup,
        'args': {
          'key': 'replace_channel_forwards',
          'hasAnchor': true,
        },
      }}}),_c('black-white-list-input',{staticClass:"mb-0",attrs:{"setup":{
        'func': _vm.channelConfigSetup,
        'args': {
          'key': 'replace_channel_list',
          'hasAnchor': true,
        }
      }}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }