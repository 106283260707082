



















































































































import { InputSetups } from '../../mixins/input-setups'
import Slots from '@/components/Slots.vue'
import { BaseItemViewAction } from '@/includes/types/BaseItemViewAction'
import SaveChannelConfig from '@/components/SaveChannelConfig.vue'

import { UseFields } from 'piramis-base-components/src/components/Pi'
import EmptyData from 'piramis-base-components/src/components/EmptyData/EmptyData.vue'
import CreateIfEmpty from 'piramis-base-components/src/components/CreateIfEmpty.vue'
import PageTitle from 'piramis-base-components/src/components/PageTitle.vue'

import { Component, Mixins } from 'vue-property-decorator'

@Component({
  components: {
    SaveChannelConfig,
    CreateIfEmpty,
    Slots,
    PageTitle,
    EmptyData
  }
})
export default class PublishSettings extends Mixins<UseFields, InputSetups>(UseFields, InputSetups) {
  gotoStyleCreate() {
    this.$router.push({
      name: "Style",
      params: {
        actionType: BaseItemViewAction.New
      }
    })
  }

  destroyed(): void {
    this.$store.dispatch('AdminTemplate/hideSaveConfigButton')
  }

  mounted(): void {
    this.$store.dispatch('AdminTemplate/showSaveConfigButton')
  }
}
